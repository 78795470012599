import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import {Handle} from 'reactflow';
//import {Handle} from 'react-flow-renderer';
import {Form} from '../';
import {Chatbot} from '../../states/actions';
import {useSelector} from 'react-redux';
import {onSuccess, onEdit, onSetStart, onRemove, onClose, isLightColor} from '../../helpers/block-helper';
import {Link} from 'react-router-dom';
import {IntNodeComponent, InputComponent, SelectComp} from './Components'



export const CalendarBlock = React.memo((node) => {
  const block = Chatbot.getBlock(node.id);
  const {start, review} = useSelector(Chatbot.get());
  const bot = useSelector(Chatbot.get());



  if (!block) {
    return null;
  }
  const {data} = block;

  return (
    <>
        <IntNodeComponent bot={bot} name="Calendar" icon="calendar" data={data} node={node} start={start}/>
    </>
  );
});

export const CalendarEditor = ({nodeid, block}) => {
  

 
  const {data} = block;

  if (!block) {
    return null;
  }

  const variableoptions = Chatbot.getBlockVariables();

  let lang = [
    {
      label: 'Et',
      value: 'et',
      content: 'et',
    },
    {
      label: 'En',
      value: 'en',
      content: 'en',
    },
    {
      label: 'Ru',
      value: 'ru',
      content: 'ru',
    },
  ];
  const handleBlur = (e) => {   
    Chatbot.setBlockData(block.id, {[e.target.name]: e.target.value});
  };


return (
  <>
         
         {/* <Form url="/block/document" initialValues={data} onSuccess={onSuccess} successNotification={false}>
             */}

           
<InputComponent data={data} name="MESSAGE" dataname="headtext" onBlur={handleBlur}/>

              
              <div className='section'>Vali jurist/id:</div>

              <SelectComp data={data} name="Lawyer" dataname="lawyer" placeholder="Select" options={variableoptions} block={block} value="label"/>
              <i>Add more than one in future:</i>

              <SelectComp data={data} name="Service" dataname="service" placeholder="Select" options={variableoptions} block={block} value="label"/>
              <i>Need to choose, if using matchblock, since service is chosen there. For personalbooking there is an option to show all available timeslots, but this means adding service type into timeslot.</i>
              <SelectComp data={data} name="Location" dataname="location" placeholder="Select" options={variableoptions} block={block} value="label"/>
              <i>Sometimes lawyers have multiple locations, need to get input location to return only user choice.</i>
             

              <SelectComp data={data} name="UI Language" dataname="lang" placeholder="Select" options={lang} block={block} value="value"/>

              <InputComponent data={data} name="Waiting message" dataname="waitmessage" onBlur={handleBlur}/>


              
             


         {/* </Form> */}

         
  </>
  

)
};